import { TNavItem } from '../../src/types/BaseTypes.ts';
import { CompanyMetadataQuery, useCompanyMetadataQuery } from '../generated/graphql.tsx';

export type CompanyMetaDataProps = {
  companyData: CompanyMetadataQuery;
  isGabb: () => boolean;
  isScribd: () => boolean;
  isHealthie: () => boolean;
  isNewDeposits: () => boolean;
  hasYearToggle: () => boolean;
  useRevenueTableV2: () => boolean;
  isSupportTransformedRevenue: () => boolean;
  isRealCustomerCompany: boolean;
  fetchCompanyMetaData: () => Promise<CompanyMetadataQuery>;
};

export const useCompanyMetaData = (reportName: string = TNavItem.REVENUE, skip: boolean) => {
  const { data: companyData } = useCompanyMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: { reportName },
    skip,
  });

  const company = companyData?.currentCompany || ({} as CompanyMetadataQuery['currentCompany']);
  const useRevenueTableV2Companies = ['Healthie', 'Close', 'Scribd', 'CaptionsAI', 'BattlBox'];

  return {
    companyData: companyData as CompanyMetadataQuery,
    isGabb: () => company?.name === 'Gabb',
    isScribd: () => company?.name === 'Scribd',
    isHealthie: () => company?.name === 'Healthie',
    hasYearToggle: () => company?.name !== 'Stark Industries' && company?.name !== 'PayZen 2023',
    useRevenueTableV2: () => useRevenueTableV2Companies.includes(company?.name as string),
    isSupportTransformedRevenue: () => company?.name === 'Healthie' || company?.name === 'Scribd',
    isRealCustomerCompany: !!company?.isRealCustomerCompany,
    isNewDeposits: () => company?.name === 'Scribd',
  } as CompanyMetaDataProps;
};
