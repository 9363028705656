import React from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import ArrayUtil from '../../src/utils/ArrayUtil';
import DataGrid from './data_grid/DataGrid';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import ShimmerBar from './common/ShimmerBar';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { useDepositsCardWithBannerStyles } from '../features/deposits/styles.ts';
import { useGetExternalAccountDepositsQuery } from '../generated/graphql';
import { useSharedStyles } from '../utils/CssUtil';
import Banner, { Highlight } from './Banner';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from './common/MenuDropdown';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const useExternalAccountSelectorStyles = createUseStyles({
  row: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
  },

  rowLabel: {
    fontSize: '14px',
    textAlign: 'right',
    paddingRight: '12px',
  },

  dropdownContainer: {
    minWidth: '240px',
  },
});

type ExternalAccountSelectorProps = {
  accounts: {
    id: string;
    bankName: string;
    accountDescription: string;
  }[];
  currentAccountId: string;
  onSelectAccountId: (id: string) => void;
};

const ExternalAccountSelector: React.FunctionComponent<ExternalAccountSelectorProps> = ({
  accounts,
  currentAccountId,
  onSelectAccountId,
}) => {
  const classes = useExternalAccountSelectorStyles();

  const onSelectIdImpl = (id: string) => {
    if (id !== currentAccountId) {
      onSelectAccountId(id);
    }
  };

  const currentAccount = accounts.find((acc) => acc.id === currentAccountId);
  if (!currentAccount) {
    return null;
  }

  const accountsByBank = ArrayUtil.partition(accounts, (acc) => acc.bankName);
  const accountsForCurrentBank = accountsByBank[currentAccount.bankName];

  const itemStyle = {
    fontSize: '14px',
    padding: '12px 15px',
  };

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.rowLabel}>Select bank: </div>
        <div className={classes.dropdownContainer}>
          <MenuDropdown
            horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
            items={Object.keys(accountsByBank)
              .sort()
              .map((bankName) => ({
                label: bankName,
                onClick: () => onSelectIdImpl(accountsByBank[bankName][0].id),
              }))}
            menuItemStyle={itemStyle}
          >
            {currentAccount.bankName}
          </MenuDropdown>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowLabel}>Select account: </div>
        <div className={classes.dropdownContainer}>
          <MenuDropdown
            horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
            items={accountsForCurrentBank.map(({ id, accountDescription }) => ({
              label: accountDescription,
              onClick: () => onSelectIdImpl(id),
            }))}
            menuItemStyle={itemStyle}
          >
            {currentAccount.accountDescription}
          </MenuDropdown>
        </div>
      </div>
    </div>
  );
};

const DepositsCardWithBanner: React.FunctionComponent = () => {
  const classes = useDepositsCardWithBannerStyles();
  const sharedClasses = useSharedStyles();
  const [params, setParams] = useSearchParams();

  const externalAccountId = params.get('eaid');
  const pageNumber = params.get('p');

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useGetExternalAccountDepositsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      external_account_id: externalAccountId || null,
      page: (pageNumber ? Number(pageNumber) : 1) || 1,
    },
  });

  React.useEffect(() => {
    refetch({
      external_account_id: externalAccountId || null,
      page: (pageNumber ? Number(pageNumber) : 1) || 1,
    });
  }, [externalAccountId, refetch, pageNumber]);

  const [isTablePaging, updateIsTablePaging] = React.useState(false);

  if (error) {
    return <div className={sharedClasses.main}>Error: {error.message}</div>;
  }

  const timezoneLocation = data?.externalAccountDeposits?.timezoneLocation;
  const pagerVariables = {
    currentPage: data?.externalAccountDeposits?.currentPage || 1,
    pageCount: data?.externalAccountDeposits?.pageCount || 1,
    pageSize: 50,
    refetchPage: (pageNum: number) => {
      updateIsTablePaging(true);
      setParams({
        ...(data
          ? {
              eaid: data.externalAccountDeposits.currentExternalAccountId,
            }
          : {}),
        p: String(pageNum),
      });
      refetch({
        external_account_id: externalAccountId || null,
        page: (pageNumber ? Number(pageNumber) : 1) || 1,
      }).then(() => {
        updateIsTablePaging(false);
      });
    },
    shouldDisableChangingPageSize: true,
  };

  const columns = data?.externalAccountDeposits?.rows?.[0]
    ? [
        {
          title: 'Date',
          field: 'date',
          minWidth: 90,
        } as TabulatorTypes.ColumnDefinition,
        ...getColumns(data.externalAccountDeposits.rows[0], false, sharedClasses, timezoneLocation, true),
      ]
    : [];
  columns.forEach((col) => {
    col.headerSort = false;
    if (col.title === 'Reconciliation') {
      col.minWidth = 150;
      col.width = undefined;
    } else {
      col.widthGrow = 1.2;
    }
  });
  const flattenedData = data?.externalAccountDeposits?.rows
    ? flattenData(data?.externalAccountDeposits.rows, 'date')
    : [];

  const options: TabulatorTypes.Options = {
    data: flattenedData,
    sortMode: 'remote',
    persistence: undefined,
  };

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={classes.header}>
          {data ? (
            <>
              <div className={classes.headerLeft}>
                {(() => {
                  const currentAccount = data.externalAccountDeposits.externalAccounts.find(
                    (acc) => acc.id === data.externalAccountDeposits.currentExternalAccountId
                  );
                  return currentAccount ? (
                    <>
                      <h2>Bank Reconciliation: {currentAccount.bankName}</h2>
                      <div className={classes.currentAccount}>
                        <strong>Account:</strong> {currentAccount.accountDescription}
                      </div>
                      <div className={classes.balanceDate}>
                        Balance information as of {data.externalAccountDeposits.latestDateStr}
                      </div>
                    </>
                  ) : null;
                })()}
              </div>
              <div className={classes.headerRight}>
                <ExternalAccountSelector
                  currentAccountId={data.externalAccountDeposits.currentExternalAccountId}
                  accounts={data.externalAccountDeposits.externalAccounts}
                  onSelectAccountId={(id) => {
                    setParams({
                      eaid: id,
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <div style={{ margin: '12px 0 7px' }}>
                <ShimmerBar delayMs={0} height={30} width={300} isLightMode />
              </div>
              <div>
                <ShimmerBar delayMs={200} height={20} width={300} isLightMode />
              </div>
            </div>
          )}
        </div>

        <div className={classes.highlightContainer}>
          {isLoading
            ? ArrayUtil.range(4).map((idx) => <Highlight isLoading isLightMode key={`highlight-${idx}`} />)
            : (data?.externalAccountDeposits?.highlights || []).map((ea, idx) => (
                <Highlight isLoading={false} isLightMode value={ea.value} header={ea.label} key={`highlight-${idx}`} />
              ))}
        </div>

        <div className={sharedClasses.content}>
          {isLoading ? (
            <div className={classes.loaderContainer}>
              <LoaderAnimation height={80} />
            </div>
          ) : (
            <>
              <div className={sharedClasses.contentHeaderWrapper}>
                <div className={sharedClasses.contentHeaderUtils}>
                  <ExportButton type={TCsvExportType.DEPOSITS_REPORT} searchParams={params} enabled />
                </div>
              </div>
              <div className={classes.tableHeader}>Daily Summaries</div>
              {isTablePaging ? (
                <div className={sharedClasses.contentLoaderContainer}>
                  <LoaderAnimation height={80} />
                </div>
              ) : (
                <DataGrid
                  className={classes.depositsTable}
                  columns={columns}
                  options={options}
                  shouldEnablePager={true}
                  pagerVariables={pagerVariables}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositsCardWithBanner;
